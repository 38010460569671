.title {
  text-align: center;
  padding-bottom: 1em;
}

.container {
  max-width: 30em;
  margin: 5em auto;
  font-family: "Courier Prime Bold";
}

.row {
  display: grid;
  grid-template-columns: 2.5em auto 8em;
  border: 2px grey solid;
  border-radius: 0.5em;
  margin: 0.5em 1em;
  padding: 0.5em 1em;
}

.skinnyRow {
  display: grid;
  grid-template-columns: 2.5em auto 6em;
  border: 2px black solid;
  border-radius: 0.5em;
  margin: 0.2em 1em;
  padding: 0.15em 1em;
  text-align: left;
}

.rank {
  padding-right: 0.5em;
}

.right {
  text-align: right;
}

.age {
  font-size: 0.8em;
}

.ownRow {
  border: 2px dashed black;
}

.first {
  background-color: #d6af36;
}

.second {
  background-color: #a7a7ad;
}

.third {
  background-color: #a77044;
}

.footerContainer {
  margin: 0 auto;
  padding-bottom: 0.5em;
  text-align: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 3em auto 10em;
}

.links {
  display: flex;
  justify-content: right;
  padding: 0.1em;
  margin: 0.1em;
  padding-right: 1em;
}

.links a {
  margin: 0.5em;
}

.links img {
  width: 1.75em;
}

.windowContainer {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.windowContainer div {
  flex-shrink: 0;
  flex-grow: 0;
}

.divButton {
  border: 2px gray solid;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
}

.divButton:hover {
  background-color: gray;
  color: black;
  cursor: pointer;
}

.modalClose {
  padding: 0.25em 0.5em;
}

.link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.bold {
  font-family: "Courier Prime Bold";
}
