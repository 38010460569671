body {
  background-color: #ebf5fb;
}

.container {
  min-width: 30rem;
  width: 99%;
  margin: 0 auto;
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.header {
  text-align: center;
}

.header h1 {
  margin: 0;
}

.smallText {
  font-size: 0.75rem;
}

.challengeDetails {
  display: grid;
  align-items: end;
}

.dailyChallengeBox {
  display: grid;
  grid-template-rows: 2em 1.5em auto;
}

.dailyChallengeBox h4 {
  font-family: "Courier Prime Bold";
  display: inline-block;
  margin: 0 auto;
}

.dailyInfoBlock {
  padding-top: 0.5em;
}

.dailyCountdown {
  display: inline-block;
  margin: 0 auto;
}

.dailyRecords {
  padding-top: 1rem;
}

.flexBreak {
  height: 1px;
  width: 100%;
}

@media (max-width: calc(28em * 3/0.99)) and (min-width: calc(28em * 2/0.99)) {
  .dailyChallengeBox {
    width: 46rem !important;
    height: 14rem !important;
    grid-template-columns: 23.8rem auto;
    grid-template-rows: 2rem 1.5rem auto;
    grid-template-areas:
      "title pic"
      "countdown ."
      "info .";
  }

  .dailyThumbnail img {
    height: 11rem !important;
    width: 18.37rem !important;
  }

  .dailyThumbnail {
    padding-top: 0.25rem;
    grid-area: pic;
    justify-self: end;
    align-self: start;
    height: unset !important;
    width: unset !important;
  }

  .dailyCountdown {
    grid-area: countdown;
  }

  .dailyTitle {
    grid-area: title;
    align-self: end;
  }

  .dailyInfoBar {
    grid-area: info;
    justify-self: center;
    align-self: start;
    padding-top: 0.5em !important;
  }
}

@media (max-width: calc(28em * 3 / 0.99)) {
  .dailyChallengeBox {
    order: -4;
  }

  .flexBreak {
    order: -3;
  }

  .yesterdaysDaily {
    order: -2;
  }

  .championshipCard {
    order: -1;
  }
}

@media (min-width: calc(28em * 3 / 0.99)) {
  .yesterdaysDaily {
    order: -3;
  }

  .dailyChallengeBox {
    order: -2;
  }

  .championshipCard {
    order: -1;
  }
}

@media (min-width: calc(28em * 3 / 0.99)) and (max-width: calc(28em * 4 / 0.99)) {
  .flexBreak {
    display: none;
  }
}

.dailyLinks {
  display: grid;
  grid-template-columns: 50% auto;
}

.challengeGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.challengeGridElement {
  border-radius: 1rem;
  border: 0.2rem black solid;
  padding: 1rem;
  margin: 1rem;
  display: inline-grid;
  grid-template-rows: 6.5em auto;
  background-color: lightgray;
  width: 26rem;
  height: 100%;
}

.topBar {
  display: grid;
  grid-template-columns: 15rem 8.3rem;
}

.dailyInfoBar {
  display: grid;
  grid-template-columns: 15rem 8.3rem;
  padding-top: 1em;
}

.challengeButtons {
  display: grid;
  grid-template-rows: 55% 40%;
}

.challengeButtons a {
  width: 100%;
}

.challengeName {
  font-family: "Courier Prime Bold";
  display: flex;
  align-items: center;
}

.challengeName span {
  margin: unset;
}

.challengeInfo {
  display: grid;
  grid-template-columns: 10em auto;
}

.challengeLink {
  background-color: lightgray;
  color: black;
  font-family: "Courier Prime Bold";
  margin: 0.2rem;
  padding: 0.5rem;
  border: 2px solid black;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.challengeWR {
  font-family: "Courier Prime Bold";
}

.challengeLink:hover {
  color: black;
  background-color: grey;
}

.thumbnail {
  width: 23.6rem;
  height: 14.13rem;
}

.thumbnail img {
  height: 14.13rem;
  width: 23.6rem;
}

.thumbnailContainer img {
  border-radius: 0.5rem;
  background-color: #909497;
}

.thumbnailLoader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnailLoader img {
  width: 32px;
  height: 32px;
  background-color: transparent;
}

.dailyThumbnail img {
  background-color: black;
  border: 0.25em black solid;
  border-radius: 0.5em;
  width: 100%;
  height: 100%;
}

.age {
  margin-left: 0.1rem;
}

.bigLink {
  grid-column: 1/3;
}

.bigCards {
  display: flex;
  grid-gap: 1em;
  margin: 0 auto;
}

.bigCard {
  width: 26rem;
  height: 30em;
  border-radius: 1rem;
  border: 0.2rem black solid;
  padding: 1rem;
  margin: 1rem;
  background-color: lightgray;
  font-family: "Courier Prime Bold";
}

.bigCardLeaderboard {
  display: flex;
  flex-direction: column;
}

.bigCard h5 {
  margin: 0.1em 0 0.1em 0;
}

.bigCardHr {
  height: 0.15rem;
  background-color: black;
  margin: 0 2rem;
}

/* ChampionshipCard */
.championshipCard {
  display: inline-block;
  text-align: center;
}

.championshipCard h4 {
  margin: 0;
}

/* YesterdaysDailyCard */
.yesterdaysDaily {
  display: inline-block;
  text-align: center;
}

.yesterdaysDaily h4 {
  margin: 0;
}
