.tooltip {
  padding: 0.4em 0.8em !important;
  font-size: 1em !important;
  border-left: 0px solid black !important;
  text-align: center !important;
  opacity: 1 !important;
}

.baseBadge,
.baseBadge :hover {
  text-decoration: none;
  color: black;
}

.baseBadge {
  padding: 0.05em 0.15em;
  border-radius: 0.25em;
  cursor: pointer;
}

.active {
  background-color: #a9cce3;
}

.badgeRow svg {
  height: 1.1em;
  padding-right: 0.25em;
}

.badgeRow {
  padding: 0.1em 0.4em;
  margin: 0.25em 0;
  border-radius: 0.25em;
  color: black;
  font-size: 0.9em;
  font-weight: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.badgeRow > span {
  margin-top: 0.15em;
}

.adminLink {
  text-decoration: none;
}
