.container {
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: darkslategray; */
  border-bottom: 2px black solid;
  right: 0;
  height: 1.75em;
  line-height: 1.75em;
  display: grid;
  grid-template-columns: 14em auto 14em;
  background-color: lightgray;
  overflow: hidden;
}

.username {
  vertical-align: middle;
  text-align: right;
}

.loginLink {
  color: blue;
  padding: 0 1em;
}

.personalRecord {
  margin-left: 1em;
}

.worldRecord {
  text-align: center;
}
